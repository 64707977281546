import React, { useState, useEffect } from 'react';
import classes from './Share.module.css';
import {
    WhatsappShareButton, WhatsappIcon,
    FacebookMessengerShareButton, FacebookMessengerIcon,
    TelegramShareButton, TelegramIcon,
    EmailShareButton, EmailIcon,
  } from "react-share";

import Button from '../UI/Button';
import icon_share from '../../assets/icons/icon_share.png';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import Alert from '../Modals/Alert';

const ShareSocials = ( { group, message, requestHandler, items } ) => {

  const [alertMessage, setAlertMessage] = useState();

  const title= `Help out for ${group?.festival?.name}`;
  const itemIdArray = items?.map( item => item.id);
  const itemIDs = itemIdArray?.toString();
  
  const shareLink = `https://list.campingbuddy.com.au/share/group?id=${group.id}&items=${itemIDs}`
  //const shareLink = `https://camping-buddy-sandpit-site.web.app/share/group?id=${group.id}&items=${itemIDs}`
  //console.log (shareLink);

    /*const logActivity = async () => {
        /*const newActivity = {
          userid: user.uid,
          activity: 'share',
          festival: group?.festival?.name,
          createdAt: Timestamp.fromDate(new Date())
        };
        try {
          //add new user to log collection
          await addDoc(collection(db, "log"), newActivity);
        } catch (err) {
          console.log(err.message);
        }
      }*/

      const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          if (successful) {
            setAlertMessage({ title:"Share link created!", message: "A link has been copied to the clipboard. You can paste this into a message to share this list with people."});
          } else {
            setAlertMessage({ title:"Oops", message: "We had some trouble creating a link."});
          }
        } catch (err) {
            setAlertMessage({ title:"Oops", message: "We had some trouble creating a link."});
        }
        document.body.removeChild(textArea);
      }

      const copyShareLink = () => {
        requestHandler('link');
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(shareLink);
            return;
          }
          navigator.clipboard.writeText(shareLink).then(function() {
            setAlertMessage({ title:"Share link created!", message: "A link has been copied to the clipboard. You can paste this into a message to share this list with people."});
          }, function(err) {
            setAlertMessage({ title:"Oops", message: "We had some trouble creating a link. Error: "+err});
          });
          //logActivity();
    }
  

    return (
       
       <div className={classes.shareSocialButtons}>

        <Button className='noStyle' onClick={()=>requestHandler('email')}>
          <EmailShareButton 
            url={shareLink}
            subject={title}
            body={message}
            className={classes.shareSocialButton}
            >
            <EmailIcon size={60} round/>
          </EmailShareButton>
        </Button>

        <Button className='noStyle' onClick={()=>requestHandler('whatsapp')}>
          <WhatsappShareButton
            url={shareLink}
            title={message}
          >
            <WhatsappIcon size={60} round />
          </WhatsappShareButton>
        </Button>
        

        {/*<Button className='noStyle' onClick={()=>requestHandler('telegram')}>
          <TelegramShareButton
            url={shareLink}
            title={message}
          >
            <TelegramIcon size={60} round />
          </TelegramShareButton>
        </Button>*/}
        

        {/*<Button className='noStyle' onClick={()=>requestHandler('messenger')}>
          <FacebookMessengerShareButton
            url={shareLink}
            appId="1467986113874946"
          >
            <FacebookMessengerIcon size={60} round />
          </FacebookMessengerShareButton>
        </Button>*/}
        

        {<Button 
          className='round'
          onClick={copyShareLink}
          >
          <LinkIcon fontSize='large'/>
          </Button>}

          {alertMessage && 
            <Alert 
                title={alertMessage.title}
                message={alertMessage.message}
                onConfirm={() => setAlertMessage()}
                />
            }
    
        </div>

    );

}

export default ShareSocials;