import classes from './Tab.module.css';
import Button from '../UI/Button';
import Subheading from '../UI/Subheading';
import ClearIcon from '@mui/icons-material/Clear';

const Tab = ({ title, children, closeHandler, item, group, bgGradient }) => {

  return (

    <div className={classes.tabBg} >

      <Button className='noStyle' style={{'position': 'absolute', 'top':'25px', left:'5px'}} onClick={closeHandler}>
          <ClearIcon style={{ color: '#b10000', }}  title='Close'/>
      </Button>

      <div className={classes.tabTopSpace} onClick={closeHandler} >&nbsp;</div>

      <div className={`${classes.tabContainer} ${bgGradient && classes.tabGradient}`} id="tab-container">

        <div className={classes.tabTopStripeContainer}>
          <div className={classes.tabTopStripe}>&nbsp;</div>
        </div>

        <Subheading item={item} group={group}>{title}</Subheading>

        {children}

      </div>
    </div>


  )
}

export default Tab;
