

import classes from './List.module.css';

import GroupNotes from '../Modals/GroupNotes';
import ListAdd from './ListAdd';

const ListOptions = ({ group, mode }) => {

    
    return (
        <div className={classes.checklistBottom}>

            <div className={classes.checklistButtonGroup}>
            
            <GroupNotes group={group} />
            
            {/*mode === 'masterlist' && <ListAdd group={group} />*/}
            
            </div>
        </div>
    );

}

export default ListOptions;