

import classes from './List.module.css';
import ListAdd from './ListAdd';

const ListTop = ({ group, mode, numberItems }) => {

    const title = 
    (mode === 'havelist') ? 'Packing list' :
    (mode === 'needlist') ? 'Gear you need' : 
    'Here is what you need...';

    const subtitle = 
    (numberItems === 0) ? '' :
    (mode === 'havelist') ? 'Use this to help make sure you pack all the things you need' :
    (mode === 'needlist') ? 'This is what you still need. Why not borrow these items?' : 
    'Do you have everything?';

    const method = 
    (mode === 'havelist') ? 'Bring' :
    (mode === 'needlist') ? 'Need' : 
    null;

    return (
        <div className={classes.checklistTop}>
            <div className={classes.checklistTopTitle}>
                <h2 className={classes.checklistTitle}>{title}</h2>
                <div className={classes.checklistsubTitle}>{subtitle}</div>
            </div>
            <div className={classes.checklistTopAdd}>
                <ListAdd group={ group } method={ method }/>
            </div> 
        </div>
    );

}

export default ListTop;