import React, { useMemo, useState, useContext, useEffect } from 'react';

import classes from './List.module.css';
import ListSectionOpen from './ListSectionOpen';
import ListTop from './ListTop';
import Button from '../UI/Button';
import AddIcon from '@mui/icons-material/Add';

const HaveList = ({ group, setCurrentScreen }) => {

    const readonly = false; 

    //filtermembers is just set to the user's id in this iteration (no group functionality)
    const [filterMembers, setFilterMembers] = useState([...group?.memberIds]);

    const sectionItems = useMemo(() => {
        //list and organise items according to sort mode 
        const organisedItems = [];
         //organise by categories
         group.categories.forEach((category, index) => {
            //get any group items that have this category
            const categoryItems = group?.items.filter(item => (item.categories.includes(category)))
            //Sort alphabetically (name)
            if (categoryItems.length > 1) categoryItems.reverse();
            //set up object for the category and add items as an array
            const categoryObj = { id: index, title: category, items: categoryItems }
            //add category object to filtered items array
            organisedItems.push(categoryObj);
        });
        return organisedItems;
    }, [group]);

    return (

        <div className={classes.checklistContainer}>

            <ListTop group={group} mode='havelist'/>

            <div className={classes.checklistSections}>
            {
                sectionItems?.map((section, index) => (
                    <ListSectionOpen
                        group={group}
                        key={index}
                        section={section}
                        filterMembers={filterMembers}
                        readonly={readonly}
                        mode='have'
                    />
                ))
            }
        </div>

       {/* <div className={classes.checklistButtonHolder}>
            <Button 
                className='primary'
                onClick={() => setCurrentScreen('masterlist')}
                >
                    <AddIcon  />
                    Add more to this list
            </Button>
        </div>*/}
        

        </div>

    );

}

export default HaveList;